import type { ConsentTypes } from "@solvari/common-fe/native";

import { onSetCookie } from "@solvari/common-fe/helpers";
import { initCookiePopup } from "@solvari/common-fe/native";

initCookiePopup();

declare global {
  interface Cookies {
    solvariCookieConsent: ConsentTypes;
  }
}

onSetCookie("solvariCookieConsent", replaceMarketingCookieBlockers);

function replaceMarketingCookieBlockers(cookieConsentCookie: ConsentTypes) {
  if (!cookieConsentCookie.includes("ad_storage")) {
    return;
  }

  document
    .querySelectorAll<HTMLElement>(
      ".marketing-cookie-blocker[data-type=youtube]",
    )
    .forEach((element) => {
      if (!element.dataset.url) {
        return;
      }
      element.outerHTML = `
        <div class="video-container">
          <iframe allowFullScreen frameBorder="0" src="${element.dataset.url}"></iframe>
        </div>
      `;
    });
}
