import type { TupleToUnion } from "type-fest";

import { createGtmEvent } from "@/lib/argus/gtm";
import { setCookie } from "@/lib/helpers/cookies";

const consentTypes = [
  "functionality_storage",
  "analytics_storage",
  "ad_storage",
] as const;

const alwaysGrantedConsentTypes: ConsentTypes = ["functionality_storage"];

type ConsentType = TupleToUnion<typeof consentTypes>;

type ConsentTypes = readonly ConsentType[];

type ConsentState = Record<ConsentType, "denied" | "granted">;

function acceptAll() {
  acceptCookies(consentTypes);
}

function acceptCookies(acceptedCookies: ConsentTypes) {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);

  const acceptedTypes = [
    ...new Set([...acceptedCookies, ...alwaysGrantedConsentTypes]),
  ];

  setCookie({
    name: "solvariCookieConsent",
    value: acceptedTypes,
    expires: date.toUTCString(),
  });
}

function sendConsentUpdateEvent(cookieConsentCookie: ConsentTypes) {
  void createGtmEvent("consent_update", toConsentState(cookieConsentCookie));
}

function toConsentState(cookieConsentCookie: ConsentTypes) {
  const acceptedTypes = [
    ...new Set([...cookieConsentCookie, ...alwaysGrantedConsentTypes]),
  ];

  return consentTypes.reduce((consentState, consentType) => {
    const granted = acceptedTypes.includes(consentType);

    consentState[consentType] = granted ? "granted" : "denied";

    return consentState;
  }, {} as ConsentState);
}

export {
  acceptAll,
  acceptCookies,
  type ConsentType,
  type ConsentTypes,
  sendConsentUpdateEvent,
};
